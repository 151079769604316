<script>
import { maska } from 'maska'
import { useStore } from 'vuex'
import {computed, onMounted, reactive, toRefs} from 'vue'

import { MONTHS } from '@/util/constants'

const stepName = 'DateOfBirth'

const questionText = 'What is your date of birth?'

export default {
  name: stepName,

  emits: ['next-step'],

  directives: {
    maska
  },

  setup(_, {emit}) {
    const state = reactive({
      date_of_birth: {
        month: {
          value: '0',
          error: false,
          selected: false
        },
        day: {
          value: null,
          error: false
        },
        year: {
          value: null,
          error: false
        }
      }
    })

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = () => {
      //error checking
      if (state.date_of_birth.day.value === null) {
        state.date_of_birth.day.error = true
      }

      if (state.date_of_birth.year.value === null) {
        state.date_of_birth.year.error = true
      }

      if (!state.date_of_birth.month.selected) {
        if (!state.date_of_birth.month.selected) {
          state.date_of_birth.month.error = true
        }
      }

      if (state.date_of_birth.day.error || state.date_of_birth.year.error || state.date_of_birth.month.error) return

      const dob = `${state.date_of_birth.month.value}/${state.date_of_birth.day.value}/${state.date_of_birth.year.value}`
      store.commit('SET_FIELD', {field: 'date_of_birth', value: dob})
      emit('next-step', stepName)
    }

    const monthHandler = () => {
      state.date_of_birth.month.selected = true
      state.date_of_birth.month.error = false
    }

    const dateHandler = () => {
      const parsed_day = parseInt(state.date_of_birth.day.value)
      state.date_of_birth.day.error = state.date_of_birth.day.value === null || parsed_day > 31 || parsed_day <= 0;
    }

    const yearHandler = () => {
      const parsed_year = parseInt(state.date_of_birth.year.value)
      const currentYear = new Date().getFullYear()
      const eighteen_limit = currentYear - 18

      state.date_of_birth.year.error = state.date_of_birth.year.value === null || parsed_year < 1900 || parsed_year > eighteen_limit
    }

    const store = useStore()

    return {
      ...toRefs(state),
      questionText,
      dateHandler,
      monthHandler,
      yearHandler,
      setAnswer,
      MONTHS
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{ questionText }}</h2>
      </div>
    </div>
    <div class="row date-of-birth-row">
      <div class="box month-box">
        <select
          class="select-dropdown date-of-birth-select-dropdown"
          :class="{ 'dob-error' : date_of_birth.month.error }"
          v-model="date_of_birth.month.value"
          @change="monthHandler"
        >
          <option
            value="0"
            :selected="date_of_birth.month.value"
            disabled="disabled"
          >
            Month
          </option>
          <option
            v-for="month in MONTHS"
            :value="month.val"
          >
            {{month.month}}
          </option>
        </select>
      </div>
      <div class="box date-box">
        <input
          class="form-input date-of-birth-input"
          type="text"
          placeholder="DD"
          v-maska="{mask: '##'}"
          v-model="date_of_birth.day.value"
          :class="{ 'dob-error' : date_of_birth.day.error }"
          @input="dateHandler"
        >
      </div>

      <div class="box year-box">
        <input
          type="text"
          class="form-input date-of-birth-input"
          placeholder="YYYY"
          v-model="date_of_birth.year.value"
          v-maska="{mask: '####'}"
          :class="{ 'dob-error' : date_of_birth.year.error }"
          @input="yearHandler"
        >
      </div>
    </div>
    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/styles/component_styles/questionnaire/date_of_birth.scss';
</style>