export const MONTHS = [
  {
    month: 'January',
    val: '01'
  },
  {
    month: 'February',
    val: '02'
  },
  {
    month: 'March',
    val: '03'
  },
  {
    month: 'April',
    val: '04'
  },
  {
    month: 'May',
    val: '05'
  },
  {
    month: 'June',
    val: '06'
  },
  {
    month: 'July',
    val: '07'
  },
  {
    month: 'August',
    val: '08'
  },
  {
    month: 'September',
    val: '09'
  },
  {
    month: 'October',
    val: '10'
  },
  {
    month: 'November',
    val: '11'
  },
  {
    month: 'December',
    val: '12'
  }
]

export const HEIGHTS = [
  {
    feet: "4'0\"",
    inches: 48
  },
  {
    feet: "4'1\"",
    inches: 49
  },
  {
    feet: "4'2\"",
    inches: 50
  },
  {
    feet: "4'3\"",
    inches: 51
  },
  {
    feet: "4'4\"",
    inches: 52
  },
  {
    feet: "4'5\"",
    inches: 53
  },
  {
    feet: "4'6\"",
    inches: 54
  },
  {
    feet: "4'7\"",
    inches: 55
  },
  {
    feet: "4'8\"",
    inches: 56
  },
  {
    feet: "4'9\"",
    inches: 57
  },
  {
    feet: "4'10\"",
    inches: 58
  },
  {
    feet: "4'11\"",
    inches: 59
  },
  {
    feet: "5'0\"",
    inches: 60
  },
  {
    feet: "5'1\"",
    inches: 61
  },
  {
    feet: "5'2\"",
    inches: 62
  },
  {
    feet: "5'3\"",
    inches: 63
  },
  {
    feet: "5'4\"",
    inches: 64
  },
  {
    feet: "5'5\"",
    inches: 65
  },
  {
    feet: "5'6\"",
    inches: 66
  },
  {
    feet: "5'7\"",
    inches: 67
  },
  {
    feet: "5'8\"",
    inches: 68
  },
  {
    feet: "5'9\"",
    inches: 69
  },
  {
    feet: "5'10\"",
    inches: 70
  },
  {
    feet: "5'11\"",
    inches: 71
  },
  {
    feet: "6'0\"",
    inches: 72
  },
  {
    feet: "6'1\"",
    inches: 73
  },
  {
    feet: "6'2\"",
    inches: 74
  },
  {
    feet: "6'3\"",
    inches: 75
  },
  {
    feet: "6'4\"",
    inches: 76
  },
  {
    feet: "6'5\"",
    inches: 77
  },
  {
    feet: "6'6\"",
    inches: 78
  },
  {
    feet: "6'7\"",
    inches: 79
  },
  {
    feet: "6'8\"",
    inches: 80
  },
  {
    feet: "6'9\"",
    inches: 81
  },
  {
    feet: "6'10\"",
    inches: 82
  },
  {
    feet: "6'11\"",
    inches: 83
  },
  {
    feet: "7'0\"",
    inches: 84
  },
  {
    feet: "7'1\"",
    inches: 85
  },
  {
    feet: "7'2\"",
    inches: 86
  },
  {
    feet: "7'3\"",
    inches: 87
  },
  {
    feet: "7'4\"",
    inches: 88
  },
  {
    feet: "7'5\"",
    inches: 89
  },
  {
    feet: "7'6\"",
    inches: 90
  },
]